import React, {useState} from "react"
import style from "./Menu.module.css"
import {observer} from "mobx-react";
import Board from "./Board";
import Notice from "./Notice";


const Menu = observer((props) => {

    const [selectTab, setSelectTab] = useState(1);

    const onChangeSelectTab = (value) => {
        setSelectTab(value);
    }

    return (
        <div className={style.menu}>
            {/*<div className={style.tab}>*/}
                {/*<div className={selectTab === 1 ? style.select : style.item} onClick={()=>onChangeSelectTab(1)}>*/}
                {/*    /!*<FontAwesomeIcon className={style.icon} icon={faQuestion}/>*!/*/}
                {/*    <span className={style.text}>요청사항</span>*/}
                {/*</div>*/}
                {/*<div className={selectTab === 2 ? style.select : style.item} onClick={()=>onChangeSelectTab(2)}>*/}
                {/*    /!*<FontAwesomeIcon className={style.icon} icon={faBullhorn}/>*!/*/}
                {/*    <span className={style.text}>공지사항</span>*/}
                {/*</div>*/}
            {/*</div>*/}
            {/*<div className={style.content}>*/}
            {/*    { selectTab == 1 && <Board/> }*/}
            {/*    { selectTab == 2 && <Notice/> }*/}
            {/*</div>*/}
            <div className={style.notice}>
                <p className={style.content}>
                    2023년 10월 3일 서버 이전 작업이 완료되었습니다.<br/>
                    서비스 이용 중 장애가 발생할 수 있습니다.</p>
                <p className={style.contact}>dev.chulbong@gmail.com</p>
            </div>
        </div>
    )
});

export default Menu;