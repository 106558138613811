import axios from "../utils/axios"

/*local
http://192.168.31.197:8080/
 */

export function GetMapPositionsAll(){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps');
    return axios.get(url).then((response) => {
        response = response;
        return response;
    })
}

export function GetMapPositionsOne(param){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps/' + param.position_id);
    return axios.get(url).then((response) => {
        response = response;
        return response;
    })
}

export function putItem(param){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps/board');
    return axios.put(url, param).then(response=>{

    })
}

export function getMapPositionsBoards(param){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps/board/' + param.position_id);
    return axios.get(url).then((response) => {
        return response;
    })
}

export function addMapPosition(param){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps');
    return axios.put(url, param).then((response) => {
        return response
    })
}

export function addMapComment(param){
    const url = axios.getURL('https://u0cvsfhkc3.execute-api.ap-northeast-2.amazonaws.com/maps/board');
    return axios.put(url, param).then((response) => {
        return response
    })
}

